@import '../../assets/scss//partials/vars';
@import '../../assets/scss/partials/fluidtypography';

body {
    background-color: grey;
}

.home {
    display: block;
    position: relative;
}

.dark-green {
    color: $dark-green;
}

.bright-green {
    color: $bright-green;
}

.header-font {
    font-family: $headerFont;
}

.subheader-font {
    font-family: $subHeaderFont;
}

.section {
    display: block;
    position: relative;
    width: 100vw;
}

.section-1 {
    height: auto;

    .block-header {
        height: 50vh;
        @media(min-width:$screen-md) {
            height: 100vh;
        }
    
        .logo {
            width: 30%;
            max-width: 300px;
            margin-left: 10px;
            @media(min-width:$screen-md) {
                margin-left: 50px;
            }
        }
    
        h1 {
            font-family: $headerFont;
            font-size: 13vw;
            line-height:11vw;
            margin: 100px 0 0 10px;
            color: $white;
            text-transform: uppercase;
            @media(min-width:$screen-md) {
                margin: 100px 0 0 50px;
            }
    
            span {
                display: block;
            }
        }
    }

    .block-subheader {
        //height: vh;
        min-height: 600px;
        h2 {
            font-family: $subHeaderFont;
            font-size: 4.5vw;
            font-weight:500;
            text-align: center;
            color: $white;
            margin: 100px auto 50px auto;
            max-width: 800px;
            width: 90%;
            @media(min-width:$screen-md) {
                font-size:1.7rem;
                width: 80%;
            }
        }

        .download-button {
           display:none;
            @media(min-width:$screen-md) {
                display:block;
                position: fixed;
                top: 18px;
                right: 30px;
            }
        }
    }
}

.bganim {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: calc(100% + 100px);
    z-index: 0;
    background-color: #000;
}

.bganim > video {
   // display: block;
   // position: fixed;
    /* top: 0px;
    left: 0px; */

   // height: 100%;
  //  width: 177.77777778vh; /* 100 * 16 / 9 */
  //  min-width: 100%;
  //  min-height: 56.25vw; /* 100 * 9 / 16 */
  //  top: 50%;
 //   left: 50%;
 //   transform: translate(-50%);
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    opacity: 0.5;
}

.section-block {
    display: block;
    position: relative;
    width: 100%;
    padding: 20px;
    z-index: 1;
}

.img-stretch {
    display: block;
    position: relative;
    max-width: 100%;
    width: 100%;
    height: auto;
}

h3.section-title {
    font-family: $headerFont;
    text-transform: uppercase;
   // font-size: 8vw;
   @include fluid-font(3.5rem, 6rem);
    color: $white;
    @media(min-width:$screen-lg) {
      //  font-size:6.3rem;
        line-height:.9;
    }
}

p {
    line-height:1.3;
}