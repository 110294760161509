@import '../../assets/scss/partials/vars';

.section-faq {
    background-color: $white;
    color: $dark-green;
   
   .section-title {
       color: $dark-green;
       line-height:.85;
   }

   &.section-block {
       padding: 50px 30px 50px;
        @media(min-width:$screen-md) {
          padding: $padding-block-desktop;
        }
   }

   .collapsible-wrapper {
    margin-top: 40px;
    font-family: $subHeaderFont;
    font-weight: 600;

    .Collapsible {
      color:$dark-green;
      border-bottom: 1px solid $dark-green;

      @media (min-width: 850px) {
        width: 100%;
       
      }
     // margin-bottom: 25px;
    }

    .Collapsible:first-child {
      margin-top:85px;
      @media(min-width:850px) {
        margin-top:0;
      }
    }
    .Collapsible__trigger {
      display: block;
      width:95%;
      font-size: 1.1rem;
      //padding: 24px 50px 24px 24px;
      text-decoration: none;
      cursor: pointer;
      position: relative;
      //padding-bottom: 25px;
      //padding: 0 25px 25px 0;
      padding: 25px 25px 25px 0;
      @media(min-width:$screen-md) {
        padding: 25px 0 25px 0;
        font-size: 1.3rem;
      }
    }

    .Collapsible__contentInner {
      p {
        color: $dark-green;
        width:80%;
        font-size:1.1rem;
        line-height: 1.4;
        font-weight:500;
        margin-bottom:20px;
      }
    }

    .Collapsible__trigger:after {
      font-size: 3rem;
      content: "+";
      position: absolute;
      right: -5%;
      top: 5px;
      display: block;
      transform-origin: center;

      transition: transform 300ms;
    }

    // .collapsible__trigger.is-open:hover:after {
    //   content:"-";
    // }

    .Collapsible__trigger.is-open:after {
      content: "-";
      right: -4.4%;
     // transform-origin: center;
     // transform: rotateZ(45deg);
    }
    .Collapsible__trigger.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }
}