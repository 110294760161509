@font-face {
    font-family:'SohneSchmal';
    src: url('../fonts/SohneSchmal/SohneSchmal.woff2') format('truetype');
    font-display: swap;
    font-style: normal;
} 

@font-face {
    font-family: 'GeneralSans';
    src: url('../fonts/GeneralSans/GeneralSans-Variable.woff2') format('woff2'),
         url('../fonts/GeneralSans/GeneralSans-Variable.woff') format('woff'),
         url('../fonts/GeneralSans/GeneralSans-Variable.ttf') format('truetype');
         font-weight: 200 700;
         font-display: swap;
         font-style: normal;
}

@font-face {
    font-family: 'GeneralSansMedium';
    src: url('../fonts/GeneralSansMedium/GeneralSans-Medium.woff2') format('woff2'),
         url('../fonts/GeneralSansMedium/GeneralSans-Medium.woff') format('woff'),
         url('../fonts/GeneralSansMedium/GeneralSans-Medium.ttf') format('truetype');
         font-weight: 200 700;
         font-display: swap;
         font-style: normal;
}