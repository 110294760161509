@import "../../assets/scss/partials/vars";
@import "../../assets//scss/partials/fluidtypography";

.footer {
    background-color: $bright-green;
    color: $white;
    .section-title {
        // text-align:center;
    }
    &.section-block {
        padding: 50px 30px 50px;
        @media (min-width: $screen-md) {
            padding: $padding-block-desktop;
        }
    }
    .footer-content {
        p {
            @include fluid-font(18px, 22px);
            margin-top: 50px;
            @media(min-width:$screen-md) {
                max-width: 80%;
            }
        }

        @media (min-width: $screen-md) {
            display: inline-block;
            width: 66%;
        }
    }
    .tree-logo {
        img {
            display: block;
            width: 50%;
            max-width: 200px;
            margin: 0 auto;
            margin-top: 50px;
        }
        @media (min-width: $screen-md) {
            display: inline-block;
            width: 33%;
            img {
                display: block;
                max-width: 250px;
                margin: 0 auto;
                width:100%;
                margin-top:0;
               
            }
        }
    }

    .social {
        margin-top: 50px;
        text-align: center;
        .social-icon {
            height: 64px;
            width: 64px;
           &.facebook {
               margin: 0 30px;
           }
            @media(min-width:$screen-md) {
               
              
                
            }
        }
        @media(min-width: $screen-md) {
           text-align:left;
        }
    }
}
