@import '../../assets/scss/partials/vars';

.download-link {
    text-decoration:none;

    .download-button {
    
        display: block; 
        height: auto; 
        max-width: 230px;
        background-color: #000;
        color: #fff;
        padding:10px 30px;
        font-family: 'SohneSchmal';
        font-size: 1.8rem;
        text-transform: uppercase;
        border: none;
        z-index: 100;
        transition: .5s ease all;

        img {
            width: 24px;
            margin-left:15px;
        }
        
        &:hover {
            background-color: $hover;
        }
    }
}