@import '../../../assets/scss/partials/vars';

.backdrop {
    z-index: 2;
    position: absolute;
    top: 0;
    display: block;
    height: 100vh; 
    width: 100%;
    background: $black;
    opacity: 0.3;
    transition: all 0.8s linear;
}


