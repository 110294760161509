/* VARS - WEBSITE =====================================
======================================================= */
$white: #FFFFFF;
$black: #000000;
$hover: #00cc52;
$bright-green: #00cc52;
$dark-green: #00401a;
$grey: #919191;

// General

$padding-block-mobile: 30px;
$padding-block-desktop: 70px;

// FONTS ============================================== */
$baseFont: 'GeneralSansMedium', arial, sans-serif;
$headerFont: 'SohneSchmal';
$subHeaderFont: 'GeneralSans';

/* BREAKPOINTS ======================================== */

// smartphones (portrait view)
$screen-xs: 374px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large tablets and desktops
$screen-xl: 1200px;

// Super large
$screen-max: 1600px;
