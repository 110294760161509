@import "../../assets//scss//partials/vars";
@import "../../assets/scss/partials/fluidtypography";

.section-2 {
    // height: 100vh;

    background-color: $black;
    color: $white;
    z-index: 0;
    .section-block {
      //  padding: 0 $padding-block-desktop;
     
      min-height: 570px;
      padding: 50px 30px 50px;
      @media(min-width:$screen-md) {
          padding: 0;
      }
    }

    .section-title {
      
    }

    .why-it-matters {
    }
    .why-it-matters .section-2-copy-container,
    .why-it-matters .section-2-slide {
        display:block;
      //  width: 100%;
      //  position: relative;
      //  min-height: 510px;
        width:100%;
        
        @media (min-width: $screen-md) {
            display: inline-block;
            width: 50%;
        }
    }

    .slider-wrapper {
        width:100%;
        @media(min-width:$screen-md) {
            width: 50%;
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .section-2-copy {
    
        width:100%;
        @media(min-width:$screen-md) {
            padding-left: 30px;
            display: inline-block;
            width: 50%;
            margin: 0;
            position: absolute;
            top: 50%;
            //left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(0, -50%);
        }
        @media(min-width:850px) {
            padding-left: 70px;
        }
        p {
            font-family: $baseFont;
            //  font-size:2vw;
            @include fluid-font(18px, 22px);
            margin-top: 30px;
            margin-bottom:70px;
            @media(min-width:$screen-md) {
                margin-bottom:0;
            }
            @media(min-width: $screen-xl) {
                //   font-size:22px;
                padding-right: 100px;
            }
        }
    }
    .section-2-slide {
        font-family: $subHeaderFont;
        font-weight: 700;
        text-transform: uppercase;

        .header-font {
            display: block;
            font-weight:normal;
        }
        .slick-dots li button:before {
            color: $white;
            content:'■';
            font-size: 20px;

        }
        
        .slick-slide {
            text-align: center;
        }
        @media (min-width: $screen-md) {
            //   padding-left: 100px;
        }

        .each-slide {
            position: relative;
            width: 100% !important;
            max-width: 350px;
            text-align: center;
            // padding: 24px;
            border: 20px solid $white;
            aspect-ratio: 1 / 1;

            @media(min-width:$screen-xl) {
                max-width: 430px;
            }

            .slide-contain {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }

        .slide-1 {
            .header-font {
                font-size: 9.7rem;
                line-height: 0.8;
            }
        }
        .slide-2 {
            .header-font {
                font-size: 36px;
                line-height: 0.8;
            }
            .airline {
                font-size: 6.8rem;
            }
            .industry {
                font-size: 5.3rem;
            }
        }

        .slide-3 {
            .header-font {
                font-size: 11rem;
                line-height: 0.8;
            }
        }

        .slide-4 {
            .header-font {
                font-size: 11rem;
                line-height: 0.8;
            }
            .ofco2 {
                line-height:2;
            }
        }
    }
}
