/* SASS mixin start */
/// $min - Minimum font size (px, rem)
/// $max - Maximum font size (px, rem)
/// $min-vw - Minimum viewport value lock range (px) --(optional)
/// $max-vw - Maximum viewport value lock range (px) --(optional)
/// $fallback - fallback font size (px, rem) --(optional)
@use "sass:math";

@mixin fluid-font($min, $max, $min-vw: 768px, $max-vw: 1600px, $fallback: false) {
    $min-no-unit : strip-units($min);
    $max-no-unit : strip-units($max);
    
    $min-vw-no-unit : strip-units($min-vw);
    $max-vw-no-unit : strip-units($max-vw);
    
    @if getUnit($min) == "rem" or getUnit($min) == "em" {
      $min-no-unit : strip-units($min) * 16;
      $max-no-unit : strip-units($max) * 16;
    }
    
     $responsive : calc(#{$min-no-unit}px + (#{$max-no-unit} - #{$min-no-unit}) * ((100vw - #{$min-vw}) / (#{$max-vw-no-unit} - #{$min-vw-no-unit})));
    
    @if $fallback { font-size: $fallback; }
    @else { font-size: $max; }
  font-size:  $responsive;
  @media screen and (max-width: #{$min-vw}) { font-size: $min; }
     @media screen and (min-width: #{$max-vw}) { font-size: $max; }
  }
  @function strip-units($number) {
    @return math.div($number, $number * 0 + 1);
  }
  @function getUnit($value) {
    @return str-slice($value * 0 + "", 2, -1);
  }
  /* SASS mixin end */