@import "../../assets/scss/partials/vars";
@import '../../assets/scss/partials/fluidtypography';

.section-3 {
    background-color: $bright-green;
    z-index: 1;
    
    & .section-block {
        padding: 50px 30px 50px;
        @media(min-width:$screen-lg) {
          padding: $padding-block-desktop;
        }
    }
  
    .section-title {
        text-align: center;
        line-height:.85;
    }

    .how-it-works-wrapper {
        font-family: $baseFont;
        margin-top: 70px;

        // .fade-works {
        //     display: inline-block;
        //     width: 33.33%;
        // }
        .fade-works {
            color: $white;
            display:inline-block;
            width: 100%;
            text-align: center;
            vertical-align: top;
            @media(min-width:$screen-md) {
                width: 33.33%;
            }
            img, svg {
                width: 120px;
            }
            h4 {
                font-weight: 600;
                @include fluid-font(18px, 24px);
                text-transform: uppercase;
                margin-top: 20px;
                margin-bottom:30px;
                @media(min-width:$screen-md) {
                    margin-top: 30px;
                }
            }
            p {
                // width:80%;
                 margin-bottom:50px;
                 
             //  font-size:2vw;
                 @include fluid-font(18px, 22px);
                
                 @media(min-width: $screen-md) {
                     padding: 0 10px;
                     margin: 30px auto 0 auto;
                 }
                 @media (min-width: $screen-lg) {
                    // width: 80%;
                    padding: 0 50px;
                 }
                 @media(min-width:$screen-xl) {
                     padding: 0 70px;
                 }
             }
        }
    }

  
}
