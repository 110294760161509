@import "../../assets/scss/partials/vars";
@import "../../assets/scss/partials/fluidtypography";

.section-4 {
   // z-index: ;

    .section-digital-footprint {
        background-color: $grey;
        color: $white;
        h3 {
            line-height: 0.85;
        }

        p {
            @include fluid-font(18px, 22px);
            margin-top: 50px;
        }

        .download-button {
            margin-top:30px;
        }

        &.section-block {
            padding: 50px 30px 0 30px;
            @media (min-width: $screen-md) {
                padding: $padding-block-desktop $padding-block-desktop 50px $padding-block-desktop;
            }
        }

        .digital-footprint-wrapper {
            min-height: 500px;
            display:block;
          
            @media(min-width:$screen-md) {
                display: flex;
                min-height:480px;
            }

            @media(min-width: 850px) {
                min-height:500px;
            }

            @media(min-width: 900px) {
                min-height:460px;
            }

            @media(min-width:$screen-lg) {
                min-height:550px;
            }

            //position: relative;
            .screenshot-wrapper {
               // display:contents;
            }
            .screenshot {
              
               width:100%;
                @media(min-width:$screen-md) {
                    flex-grow:1;
                    flex-basis:50%;
                    order:0;
                }
              //  display: inline-block;
              //  width: 50%;

                img {
                    //width: 100%;
                    width:100%;
                    margin: 50px 0 -15% -15%;
                    filter: drop-shadow(5px 5px 5px #666);
                    //margin-left:-50%;
                    @media(min-width:$screen-md) {
                        width: 1000px;
                        position: absolute;
                        margin: 0;
                       // top: 70px;
                        right: 10%;
                    }
                }
            }
            .digital-footprint-content {
                width:100%;
                @media(min-width:$screen-md) {
                    flex-grow:1;
                    flex-basis:50%;
                    order:1;
                }
              //  display: inline-block;
              //  width: 50%;
            }
        }
    }
}
